import Button from 'react-bootstrap/Button';

import Heading from '../Heading/Heading.js' ;

import './clinic.css' ;

const Clinic = ({num, add, mobile, setShowForm}) => {
	return (
		<div className="clinic">
			<Heading text={`Clinic ${num}`} />
			<p className="clinic-add"><strong>Address:</strong> {add}</p>
			<p className="clinic-num">Phone No: {mobile}</p>
			<Button className="clinic-btn" onClick={()=> setShowForm(num)}>Book Appointment</Button>
		</div>
	) ;
}

export default Clinic ;