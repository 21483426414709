import {Fragment} from 'react' ;
import {Link} from 'react-router-dom' ;
import { faEnvelope } from '@fortawesome/free-solid-svg-icons' ;

import ScrollTopBar from './scrolltopbar/scrollTopbar.js' ;
import './topbar.css' ;
import BarItem from './BarItem.js' ;
import Logo from '../../images/logo.png' ;

const InnerTopBar = () => {
	
	return (
		<div className="topbar"> 
			<div className="logo">
				<Link to='/'><img src={Logo} alt="logo" /></Link>
				<p>Dr. Anil Jain</p>
			</div>
			<div className="right-topbar"> 
				<div className="bar-items">
					<BarItem link="mailto:draniljain@gmail.com" text=" draniljain@gmail.com" 
					icon={faEnvelope} msg="E-Mail Us" />
				</div>
			</div>
		</div>
	) ;
}

const TopBar = () => {
	return <Fragment> <InnerTopBar /> <ScrollTopBar offset={100} namecss="name-size"> <InnerTopBar/> </ScrollTopBar> </Fragment> ;
}

export default TopBar ;