import {useState, useEffect} from 'react' ;
import Col from 'react-bootstrap/Col';

import './calendar.css' ;

const venue = [null, 'Kamla Nagar', 'Adarsh Nagar'] ;

const Calendar = ({data, onInputChange, value, show}) => {
	const [dates, setDates] = useState([]) ;
	const {name, options} = data ;

	useEffect(() => {
		if(options) {
			setDates([...new Set(options.map(one => one.date))]) ;
		}
	}, [options, show]) ;

	const returnTimes = (str) => {
		return options.filter(one => one.date === str && one.clinic === venue[show] ).map((one, i) => {
			let sel ;
			if(one.booked)
				sel = 'greyed' ;
			else if(str === value.date && one.time === value.time && venue[show] === value.clinic) 
				sel = 'select2' ;
			else
				sel = '' ;
			
			return <p key={i} className={`cal2-time ${sel}`} onClick={()=>{
					if(!one.booked)
						onInputChange(name, {date: str, clinic: venue[show], time: one.time})
					}}>{one.time}</p>
		}) ;
	}

	const formatDate = (value) => {
		value = value.split(' ') ;
		value[0] = value[0].slice(0,3)+',' ;
		value[2] = value[2].slice(0,3) ;
		return value.map((one, i) => <span key={i}>{one}&nbsp;</span>) ;
	}

	return (
		<Col md>
			<div className="calendar cal2">
				{ dates.map((one, i) => {
					return (
						<div key={i} className="cal2-date">
							<p className="cal2-left"> {formatDate(one)}</p>
							<div className="cal2-right">{returnTimes(one)}</div>
						</div>
					) ;
				})}
			</div>
		</Col>
	) ;
}

export default Calendar ;