import {useState, useEffect} from 'react' ;

import Profile from '../../images/profile.png' ;

import DisplayDetailed from '../display/DisplayDetailed.js' ;
import './intro.css' ;

const features = [
'29 years of experience',
'He is a member of Association of Otolaryngologists of India (AOI).',
'Timings: (Sunday Closed) 09:00 AM - 10:00 AM and 06:00 PM - 08:30 PM(Kamla Nagar)',
'Timings: (Sunday Closed) 11:00 AM - 1:00 PM (Adarsh Nagar)',
] ;

const Intro = () => {
	const [msg, setMsg] = useState('') ;

	useEffect(() => {
		// fetch('http://localhost:8000/drmessage',{
		fetch('https://api.draniljainent.com/drmessage', {
			method : 'get' ,
			headers : { 'Content-Type' : 'application/json' } ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then( data => setMsg(data.message) ) 
		.catch( err => console.log(err) ) ;
	}, [])

	return (
		<div className="intro-main">
			<div className="intro-content">
				<h1>Dr. Anil Jain</h1>
				<p>M.B.B.S.  D.L.O.<br/> EAR, NOSE AND THROAT SURGEON <br/> Reg. No. 2974(DMC), 6331 (MCI)</p>
				<DisplayDetailed lidata={features} />
				<div className="message-main"> {msg} </div>
			</div>
			<img src={Profile} alt="profile" />
		</div>
	) ;
}

export default Intro ;