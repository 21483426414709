import { useState, useEffect } from 'react';

import BasicForm from '../basicform/BasicForm.js' ;
import { addNotif } from '../notif.js' ;

const initData = { appointDate: {} } ;

const DisableForm = ({show}) => {
	const [data, setData] = useState(initData) ;
	const [dates, setDates] = useState([]) ;
	const [empty, setEmpty] = useState(true) ;

	useEffect( () => {
		// fetch('http://localhost:8000/dralist',{
		fetch('https://api.draniljainent.com/dralist',{
			method : 'get' ,
			headers : { 'Content-Type' : 'application/json' },
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then(data => setDates(data)) 
		.catch(err => console.log(err));
	}, [empty]) ;

	const formData = [
		[	{type: "calendar2", name: "appointDate", label:"Choose Date of Appointment ", options: dates},
		], [	
			{type: "btn", name: "Disable", style: "sched-btn"},
		],
	] ;

	useEffect(()=>{
		const {flag, appointDate} = data ;

		if(flag === 'yes') {
			// fetch('http://localhost:8000/dra',{
			fetch('https://api.draniljainent.com/dra',{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json' },
				body : JSON.stringify({appointDate, name: 'disabled', mobile: '9999999999', age: 20, gender: 'Male', email : 'test123@gmail.com' }) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				throw Error(res.statusText) ;
			})
			.then(data => {	
				setData(initData) ;
				setEmpty(!empty) ;
				addNotif('Disabled Appointment Successfully', 'success') ;
			}) 
			.catch( err  => {
				console.log(err) ; 
				addNotif('Error While Disabling Appointment' , 'error') ;
			}) ;
		}
		//eslint-disable-next-line
	}, [data])

	const onDisableClick = (obj) => setData({...obj, flag: 'yes'}) 

	if(show !== '')
		return <BasicForm data={formData} errors={{}} onClick={{"Disable" : onDisableClick}} initData={initData} empty={empty} show={show}/> ;
	else return null ;
}

export default DisableForm ;