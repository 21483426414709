import { useState, useEffect } from 'react' ;
import Button from 'react-bootstrap/Button' ;

import GeneralTable from '../generaltable/GeneralTable.js' ;
import LoginForm from '../login/LoginForm.js' ;
import DropDown from '../basicform/inputs/DropDown.js' ;
import Text from '../basicform/inputs/Text.js' ;
import { addNotif } from '../notif.js' ;

import './schedule.css' ;

const Schedule = () => {
	
	const [mode, setMode] = useState('normal') ;
	const [data, setData] = useState([]) ;
	const [filterData, setFilterData] = useState([]) ;
	const [view, setView] = useState('Today') ;
	const [msg, setMsg] = useState('') ;

	useEffect( () => {

		// fetch('http://localhost:8000/drappoints',{
		fetch('https://api.draniljainent.com/drappoints', {
			method : 'get' ,
			headers : { 'Content-Type' : 'application/json' } ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then( data => setData(data.sort(compare).filter(one => one.name !== 'disabled')) ) 
		.catch( err  => console.log(err) ) ;

		// fetch('http://localhost:8000/drmessage', {
		fetch('https://api.draniljainent.com/drmessage', {
			method : 'get' ,
			headers : { 'Content-Type' : 'application/json' } ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then( data => setMsg(data.message) ) 
		.catch( err => console.log(err) ) ;
	}, []) ;

	useEffect( ()=> {
		setFilterData(data.filter( one => {
			let dateObj = new Date(one.appointDate.date+' '+one.appointDate.time) ;
			let current = new Date() ;

			if(view === "Today")
				return ((current.getDate() === dateObj.getDate()) &&
				 (current.getMonth() === dateObj.getMonth()) &&
				  (current.getYear() === dateObj.getYear()))
			else if(view === "Previous") 
				return (current > dateObj)
			else if(view === "Upcoming")
				return (current < dateObj)
			else 
				return true ;
		}))
	}, [view, data])

	const checkPassword = (password, setError) => {
		
		if(password==="DrAnilJain")
			setMode("table") ;
		else
			setError(" This Password is Incorrect") ;
	}

	const formatObj = (data, args) => data[args] ;

	const compare = ( a, b ) => {
			let datex = new Date(a.appointDate.date.split(', ')[1]+a.appointDate.time) ;
			let datey = new Date(b.appointDate.date.split(', ')[1]+b.appointDate.time) ;

			if(datex < datey)
			    return 1;
			else if(datex > datey)
			   	return -1;
		  	return 0;
		}

	const userDisplay = [
		{ name:'appointDate', title:'Clinic', format: formatObj, args: 'clinic' },
		{ name: 'appointDate', title:'Date', format: formatObj, args: 'date' }, 
		{ name: 'appointDate', title:'Time', format: formatObj, args: 'time' }, 
		{ name:'name', title: 'Name'}, 
		{ name:'age', title: 'Age'}, 
		{ name:'gender', title: 'Gender'}, 
		{ name: 'mobile', title: 'Mobile'}
	] ;

	const obj2 = {
		data: filterData,
		columns: [
			{ name:'appointDate', title:'Clinic', format: formatObj, args: 'clinic' },
			{ name: 'appointDate', title:'Date', format: formatObj, args: 'date' }, 
			{ name: 'appointDate', title:'Time', format: formatObj, args: 'time' }, 
		], 
		display: userDisplay ,
		actions: {
			before: ['sno'], 
			after: ['view'],
			search: [],
		}
	}

	const obj = {
		data: filterData,
		columns: [
			{ name:'appointDate', title:'Clinic', format: formatObj, args: 'clinic' },
			{ name: 'appointDate', title:'Date', format: formatObj, args: 'date' }, 
			{ name: 'appointDate', title:'Time', format: formatObj, args: 'time' }, 
			{ name:'name'}, 
			{name:'age'}, 
			{name:'gender'}, 
			{name: 'mobile'}
		], 
		display: {} ,
		actions: {
			before: ['sno'], 
			after: [],
			search: [],
		}
	}

	const onSaveClick = () => {
		// fetch('http://localhost:8000/drsetMessage', {
		fetch('https://api.draniljainent.com/drsetMessage', {
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json' } ,
			body: JSON.stringify({message: msg})
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then( data => {setMsg(data.message);addNotif('Message Saved', 'success')} ) 
		.catch( err => console.log(err) ) ;
	}

	const onInputChange = ({target}) => setMsg(target.value) 

	const element = {
		name : 'msg',
		label: 'Enter Home Screen Message'
	}

	if(mode === "table")
		return(
			<div className="schedule">
				<div className="msg-edit">
					<Text data={element} onInputChange={onInputChange} value={msg} />
					<Button className="clinic-btn" onClick={onSaveClick}> Save </Button>
				</div>
				<DropDown onInputChange={e=> setView(e.target.value)} value={view} error={false} data={ {name: 'view', options: ["Today", "Upcoming", "Previous", "All"]} }/>
				<GeneralTable {...((window.screen.availWidth > 600)?obj:obj2)} small={true}/>
			</div>
		) ;
	else
		return <LoginForm checkPassword={checkPassword} /> ;
}

export default Schedule ;