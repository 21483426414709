import {useState, useEffect, Fragment, createRef} from 'react' ;
import Button from 'react-bootstrap/Button' ;

import Intro from '../intro/Intro.js' ;
import Clinic from '../clinic/Clinic.js' ;
import PlannerForm from './PlannerForm.js' ;
import DisableForm from './DisableForm.js' ;

import './planner.css' ;

const Planner = ({type}) => {
	const [showForm, setShowForm] = useState("") ;

	const formRef = createRef() ;

	useEffect( () => {
		if(showForm!=="")
			window.scrollTo(0, formRef.current.offsetTop);
		//eslint-disable-next-line
	}, [showForm]) ;
	
	const returnIntro = () => {
		if(type!== 'disable')
			return <Intro /> ;
	}

	const returnClinics = () => {
		if(type === 'disable')
			return (
				<Fragment>
					<Button className="clinic-btn" onClick={()=> setShowForm(2)}>Adarsh Nagar Clinic</Button>
					<Button className="clinic-btn" onClick={()=> setShowForm(1)}>Kamla Nagar Clinic</Button>
				</Fragment>
			) ;
		else
			return (
				<div className="planner-clinic">
					<Clinic num="1" add="121-E, Kamla Nagar, Delhi-110007" mobile="011-45073264, 8447764567" setShowForm={setShowForm}/>
					<Clinic num="2" add="A-14, G.T. Road, Adarsh Nagar, Delhi-110033" mobile="011-40546515, 9354234123" setShowForm={setShowForm}/>
				</div>
			) ;
	}

	const returnForm = () => {
		if(type === 'disable')
			return <DisableForm show={showForm} /> ;
		else 
			return <PlannerForm show={showForm} setShowForm={setShowForm}/> ;
	}

	return(
		<div className="planner">
			{ returnIntro() }
			{ returnClinics() }
			<div ref={formRef} className="planner-con">{returnForm()}</div>
		</div>
	) ;
}

export default Planner ;