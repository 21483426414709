import { BrowserRouter, Route, Routes } from 'react-router-dom' ;

import Planner from './comps/planner/Planner.js' ;
import TopBar from './comps/topbar/Topbar.js' ;
import Schedule from './comps/schedule/Schedule.js' ;
import DownBar from './comps/downbar/DownBar.js' ;

import './App.css' ;

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <TopBar />
        <Routes>
          <Route path='/' element={<Planner type="calendar" />} />
          <Route path='/schedule' element={<Schedule />} />
          <Route path='/disable' element={<Planner type="disable" />} />
        </Routes>
        <DownBar />
      </BrowserRouter>
    </div>
  );
}

export default App;
