import {useState} from 'react';
import Button from 'react-bootstrap/Button';

import Text from '../basicform/inputs/Text.js' ;

import './login.css' ;

const LoginForm = ({checkPassword}) => {
	const [password, setPassword] = useState('') ;
	const [error, setError] = useState('') ;

	const formData = { 
		name: "password", 
		label: "Enter your Password", 
		id:"userPassword"
	} ;

	const handleEnter = event => {
		if(event.which === 13)
			checkPassword(password, setError) ;
	}

	const onInputChange = (event) => setPassword(event.target.value) 

	return(
		<div className="login">	
			<div className="login-form-con">
				<Text type = "password" data={formData} onInputChange={onInputChange} handleEnter={handleEnter} value={password} error={error}/>
				<Button className="clinic-btn" onClick={() => checkPassword(password, setError)}>Login</Button>
			</div>
		</div>
	) ;
}

export default LoginForm ;