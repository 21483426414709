import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;

const BarItem = ({msg, icon, link, text}) => {
	if(window.screen.availWidth > 600)	
		return (
			<div className='bar-item'> 
			 	<div className="item-txt">
		 			<FontAwesomeIcon icon={icon} />
		 		 	<a href={link}>{text}</a>
				</div>
			</div>
		);
	else
		return (
			<div className='bar-item'> 
			 	<div className="item-txt">
		 		 	<a href={link}><FontAwesomeIcon icon={icon} /></a>
				</div>
			</div>
		);
}

export default BarItem ;
