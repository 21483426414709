import { useState, useEffect } from 'react';

import { isBlank, invalidName, invalidMobile, invalidEmail } from '../valid.js' ;
import BasicForm from '../basicform/BasicForm.js' ;
import { addNotif } from '../notif.js' ;

const initData = { name: '', age: '', mobile: '', gender:'', email:'', appointDate: {} } ;

const PlannerForm = ({show, setShowForm}) => {
	const [data, setData] = useState(initData) ;
	const [error, setError] = useState({}) ;
	const [dates, setDates] = useState([]) ;
	const [empty, setEmpty] = useState(true) ;

	useEffect( () => {
		// fetch('http://localhost:8000/dralist',{
		fetch('https://api.draniljainent.com/dralist',{
			method : 'get' ,
			headers : { 'Content-Type' : 'application/json' },
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then(data => setDates(data)) 
		.catch(err => console.log(err));
	}, [empty]) ;

	const formData = [
		[	
			{type: "text", name: "name", label: "Enter Name *", id:"consultName"},
			{type: "number", name: "age", label: "Enter your Age *", id: "userAge"},
			{type: "dropdown" , name: "gender", label:"Select Gender *", options:["Male", "Female"]},
		],
		[
			{type: "text", name: "mobile", label: "Mobile No. *", id:"userMobile"},
			{type: "text", name: "email", label: "Enter your Email *", id:"userEmail"},
		],
		[	
			{type: "calendar2", name: "appointDate", label:"Choose Date of Appointment ", options: dates},
		],
		[	
			{type: "btn", name: "Request Appointment", style: "sched-btn"},
		],
	] ;

	//  2 : register karne ki request bhej raha hai.
	const sendConsultRequest = () => {
		const {name, mobile, age, gender, email, appointDate } = data ;

		// fetch('http://localhost:8000/dra',{
		fetch('https://api.draniljainent.com/dra',{
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json' },
			body : JSON.stringify({appointDate, name, mobile, age, gender, email }) ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then(data => {	
			setData(initData) ;
			setShowForm("") ;
			setEmpty(!empty) ;
			addNotif('Booked Appointment Successfully', 'success') ;
		}) 
		.catch( err  => {
			console.log(err) ; 
			addNotif('Error While Booking Appointment' , 'error') ;
		}) ;
	}

	useEffect(() => {
		const errorCount = Object.keys(error).filter(one => error[one]).length ;
		const errorKeyCount = Object.keys(error).length ;
		if(errorCount === 0 && errorKeyCount > 0) {
			sendConsultRequest() ;
		}
		//eslint-disable-next-line
	}, [error]) ;

	useEffect(()=>{
		const {flag, name, mobile,email, age, gender, appointDate} = data ;

		if(flag === 'yes') {
			const newError = {
				name: invalidName(name),
				mobile: invalidMobile(mobile),
				gender: isBlank(gender, 'Gender'),
				age: isBlank(age, 'Age'),
				email: invalidEmail(email),
				appointDate: appointDate.time?false:"You must select a time slot"
			}
			setError(newError) ;
		}
	}, [data])

	useEffect ( () => {
		if(error.appointDate )
			addNotif(error.appointDate, "error") ;
	}, [error])

	const onBookConsultClick = (obj) => setData({...obj, flag: 'yes'}) 

	if(show!=="")
		return <BasicForm data={formData} errors={error} onClick={{"Request Appointment" : onBookConsultClick}} initData={initData} empty={empty} show={show}/> ;
	else
		return null ;
}

export default PlannerForm ;