import './downbar.css' ;

const DownBar = () => {
	return (
		<div className="downbar"> 
			<div> Copyright &copy; 2022 &ensp;|&ensp; Website Created by &emsp;
				<a href="https://myarth.in" target="_blank" rel="noreferrer" ><img className="myarth" src={'https://api.myarth.in/static/images/Logo/logo-black.png'} alt="myarth" /></a>
			</div>
		</div>
	);
}

export default DownBar ;